.modal-dialog {
    max-width: fit-content;
}

input.error {
    border: 1px solid #eadae4;
}

.error {
    color: #cc0033;
    font-size: 0.7em;
    display: relative;
}
