.legends-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 510px;
    margin: 10px auto;
}

.table-sec {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 10px;
    width: 100%;

    th {
        background-color: #ed7207;
        border-color: #ed7207;
        color: #fff;

        &.keys {
            width: 70%;
        }

        &.values {
            width: 30%;
            text-align: center;
        }
    }

    td {
        &.values {
            text-align: center;
        }
    }

    th,
    td {
        padding: 6px !important;
    }

    .table-responsive {
        width: 100%;
        margin: 0 10px 0 0;
        vertical-align: top;
    }

    table {
        overflow: hidden;
        border-radius: 8px;
        font-weight: 600;
        font-size: 15px;
    }
}

.assess-footer {
    margin-top: 10px;
    padding: 20px;

    hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        border: 0;
        border-top: 2px solid #ed7207;
    }

    .comment-box {
        // line-height: 2;
        font-size: 15px;
        padding: 0 10px 10px;
    }
}