@media (max-width: 768px) {
    .top-head {
        flex-direction: column-reverse;
    }
}

.update-txt {
    @media (max-width: 768px) {
        margin-top: 10px;
    }
}

.student-cards {
    margin: 0;

    .student-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 10px;
        margin-top: 30px;

        &.disabled {
            opacity: 0.4;
            pointer-events: none;
        }

        .stu-img {
            background: #f8f9f9;
            padding: 10px;
            border-radius: 50%;

            svg {
                width: 50px;
                height: 50px;
                color: #a9a9a9;
            }
        }

        .action-btns {
            button {
                border-radius: 50%;

                &.absent {
                    color: #bd2130;
                    border: 1px solid #bd2130;

                    &.active {
                        color: #fff;
                        background-color: #bd2130;
                    }
                }

                &.present {
                    color: #1e7e34;
                    border: 1px solid #1e7e34;

                    &.active {
                        color: #fff;
                        background-color: #1e7e34;
                    }
                }
            }
        }
    }
}

.batch-cards {
    margin: 0;

    .batch-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        // border: 1px solid #ed7207;
        padding: 10px;
        margin-top: 30px;
        height: 200px;

        .action-btns {
            button {
                border-radius: 4px;
                background-color: #ed7207;
                border: none;
                color: #fff;
                text-align: center;
                font-size: 20px;
                padding: 10px;
                transition: all 0.5s;
                cursor: pointer;
                margin: 5px;
                box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.3);
                font-family: 'lato', sans-serif;
            }

            button {
                cursor: pointer;
                display: inline-block;
                position: relative;
                transition: 0.5s;
            }

            button:after {
                position: absolute;
                opacity: 0;
                top: 14px;
                right: -20px;
                transition: 0.5s;
            }

            button:hover {
                padding-right: 24px;
                padding-left: 8px;
            }

            button:hover:after {
                opacity: 1;
                right: 10px;
            }
        }
    }
}