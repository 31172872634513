.toast-wrapper {
  position: fixed;
  top: 2%;
  right: 2%;
  z-index: 10001;
  width: 100%;
}

.toast {
  min-width: 300px;
}

.toast.success {
  border-left: 5px solid var(--success);
}

.toast.success .toast-header {
  color: var(--success);
}

.toast.error {
  border-left: 5px solid var(--danger);
}

.toast.error .toast-header {
  color: var(--danger);
}
