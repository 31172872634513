.wrapper {
    height: calc(100vh - 190px);

    button {
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ed7207;
        color: #ed7207;
        text-align: center;
        font-size: 20px;
        padding: 5px 20px;
        margin: 5px;
        box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.3);
        &.active {
            background-color: #ed7207;
            color: #fff;
        }
    }

    .dropdown {
        .btn-custom {
            &:hover {
                color: #ed7207;
                background-color: #fff;
            }
            &:focus {
                outline: none;
                box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.3);
            }
        }
        &.completed {
            .btn-custom {
                color: #008000;
                border: 1px solid #008000;
            }
        }
    }

    .batch-list {
        padding: 10px 0;
        .batches {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .main-body {
        height: 95%;
        display: flex;
        .assessment-body {
            position: relative;
            width: 100%;
            box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.3);
            .assessment-tabs {
                /* Hide scrollbar for Chrome, Safari and Opera */
                // &::-webkit-scrollbar {
                //     display: none;
                // }
                
                /* Hide scrollbar for IE, Edge and Firefox */
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */

                display: flex;
                overflow: auto;
                .tab-item {
                    cursor: pointer;
                    text-align: center;
                    padding: 10px;
                    border-bottom: 1px solid #d3d3d3;
                    &.active {
                        border-bottom: 2px solid #ed7207;
                        color: #ed7207;
                    }
                }
            }
            .params-input-body {
                padding: 10px;
                height: calc(100% - 50px);
                .input-questions {
                    padding: 10px;
                    position: relative;
                    height: calc(100% - 120px);
                    overflow: auto;
                    .question {
                        box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.3);
                        padding: 10px;
                        margin-bottom: 10px;
                        .ques-opt {
                            @media screen and (max-width: 576px) {
                                button {
                                    font-size: 12px;
                                    padding: 2px 10px;
                                }
                            }
                        }
                        .ques-title {
                            margin-bottom: 5px;
                        }
                        button {
                            font-size: 16px;
                            &:disabled {
                                opacity: 0.7;
                            }
                        }
                    }
                }
                .comment-sec {
                    width: calc(100% - 20px);
                }
                textarea {
                    width: 100%;
                    border: none;
                    height: 105px;
                    border-bottom: 1px solid #ed7207;
                }
            }
        }
    }
}
