.container {
    font-family: lato;
}

.no-data {
    margin-top: 10%;
    text-align: center;
    font-size: 32px;
    color: #ed7207;
}

.schoolCards {
    margin: 0;
    .schoolCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 10px;
        margin-top: 30px;
        height: 200px;
        .editBtn {
            display: none;
            position: absolute;
            top: 10px;
            right: 0;
        }
        .actionBtns {
            button {
                border-radius: 4px;
                background-color: #ed7207;
                border: none;
                color: #fff;
                text-align: center;
                font-size: 20px;
                padding: 10px;
                transition: all 0.5s;
                cursor: pointer;
                margin: 5px;
                box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.3);
                font-family: 'lato', sans-serif;
            }

            button{
                cursor: pointer;
                display: inline-block;
                position: relative;
                transition: 0.5s;
            }

            button:after {
                position: absolute;
                opacity: 0;
                top: 14px;
                right: -20px;
                transition: 0.5s;
            }

            button:hover{
                padding-right: 24px;
                padding-left:8px;
            }

            button:hover:after {
                opacity: 1;
                right: 10px;
            }
        }
        &:hover {
            .editBtn {
                display: block;
            }
        }
    }
}

.row-disabled {
    opacity: 0.4;
}