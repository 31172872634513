#back-to-top-button {
  display: inline-block;
  background-color: #ff9800;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 5px;
  right: 5px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
#back-to-top-button svg {
  /* content: "\f077"; */
  /* font-family: FontAwesome; */
  vertical-align: text-bottom;
  font-weight: normal;
  font-style: normal;
  font-size: 1.2em;
  line-height: 50px;
  color: #fff;
}
#back-to-top-button:hover {
  cursor: pointer;
  background-color: #333;
}
#back-to-top-button:active {
  background-color: #555;
}
#back-to-top-button.show {
  opacity: 1;
  visibility: visible;
}
