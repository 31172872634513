@import url('https://fonts.googleapis.com/css?family=Lato:regular,bold,italic&subset=latin,latin-ext');

body {
  margin: 0;
  font-family: 'Lato' !important;
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import "themes/dot-flashing.css";
@import "themes/loader.css";
@import "themes/toast.css";
@import "themes/back-to-top.css";
@import "themes/search-inputs.css";

/* setting style for dropdonw menu */
.dropdown-menu {
  max-height: 250px;
  overflow: auto;
}

.bg-danger-light {
  background-color: #ffe5e5;
}

.bg-success-light {
  background-color: #e5ffe5;
}

.bg-warning-light {
  background-color: #ffeca3;
}

.hover-scale:hover {
  transform: scale(1.5);
  box-shadow: none !important;
}