.navbar-fixed {
  position: fixed !important;
  width: 100%;
  top: 0;
  z-index: 999;
  background: #fff;
  border-bottom: 1px solid #dedede;
  font-size: 1rem;
  padding-top: 2px;
  padding-bottom: 5px;
  color:rgb(128, 128, 128);
}

.user-div {
  padding: 0.5rem;
  margin: 0 !important;
  background: aliceblue;
  border-radius: 5px;
}
