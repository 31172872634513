.attend-circle {
    height: 20px;
    width: 20px;
    border: 1px solid grey;
    border-radius: 50%;
    margin: auto;
    &.absent {
        background-color: red;
    }
    &.present {
        background-color: green;
    }
    &.present_in_first {
        background-color: blue;
    }
    &.present_in_second {
        background-color: chocolate;
    }
}

.date-slider {
    display: flex;
    align-items: center;
    font-family: 'lato', sans-serif;
    svg {
        cursor: pointer;
        color: #a9a9a9;
    }
}

.table-head {
    background-color: #95A5A6;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-family: 'lato', sans-serif;
    border-collapse: separate;
    border-spacing: 30px
}

.table-body {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-family: 'lato', sans-serif;
    border-collapse: separate;
    border-spacing: 30px;
}

.table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
}