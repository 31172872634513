.main-contain {
    background-repeat: no-repeat;
    background-position: center;
    padding: 10px;

    .top-header {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        margin: 10px 0;
    }

    .header {
        display: flex;
        justify-content: space-between;
        img {
            width: 130px;
            height: 120px;
        }
        .details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .card-lm {
                display: flex;
                .key {
                    width: 150px;
                }
                .colon {
                    width: 30px;
                }
                .value {
                    text-transform: capitalize;
                }
            }
        }
        .invisible {
            visibility: hidden;
        }
    }
}
