.main-receipt-contain {
    position: relative;
    .company-container {
        padding: 5px;
        display: flex;
        justify-content: space-between;

        .company-details {
            max-width: 60%;
            .comp-logo {
                img {
                    width: 75px;
                    height: auto;
                }
            }

            .comp-name {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }

    .stu-details {
        margin-top: 20px;
        table {
            th,td{
                padding: 0.3rem;
            }
        }
    }

    .transaction-body {
        margin-top: 40px;

        .transac-title {
            font-size: 20px;
            font-weight: 600;
        }
        table {
            th,
            td {
                padding: 0.5rem;
                &.amount {
                    font-size: 14px;
                    text-align: center;
                }
                .percent-val {
                    font-size: 12px;
                }
            }
        }
    }

    .footer {
        margin-top: 70px;
    }
}