.input-group .input-group-prepend {
    width: 10%;
}

.input-group .input-group-prepend .input-group-text {
    width: 100%;
    justify-content: center;
}

.error-msg {
    font-size: smaller;
    color: red;
    margin-bottom: 10px;
}