.input-elevated {
    font-size: 16px;
    line-height: 1.5;
    border: none;
    background: #FFFFFF;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    width: 300px;
    padding: .5em 1em .5em 2.5em;
}

.input-elevated::placeholder {
    color: #838D99;
}

.input-elevated:focus {
    outline: none;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
}

.input-inset {
    font-size: 16px;
    line-height: 1.5;
    background: #FFFFFF;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'><path fill='%23838D99' d='M13.98 12.57a2 2 0 0 1 1.93.52l2.5 2.5a2 2 0 0 1-2.82 2.82l-2.5-2.5a2 2 0 0 1-.52-1.93l-1.38-1.37a7 7 0 1 1 1.42-1.42l1.37 1.38zm-3.37-2.03a5 5 0 1 0-7.08-7.08 5 5 0 0 0 7.08 7.08z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
    border: 1px solid #C5CBD5;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.20);
    border-radius: 8px;
    width: 300px;
    padding: .5em 1em .5em 2.5em;
}

.input-inset::placeholder {
    color: #838D99;
}

.input-inset:focus {
    outline: none;
    border: 1px solid #84A2FA;
}

.input-simple {
    font-size: 16px;
    line-height: 1.5;
    border: none;
    background: #FFFFFF;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
    border: 1px solid #C5CBD5;
    width: 300px;
    padding: .5em 1em .5em 2.5em;
}

.input-simple::placeholder {
    color: #838D99;
}

.input-simple:focus {
    outline: none;
    border: 1px solid #84A2FA;
}

.input-bottom-border {
    font-size: 16px;
    line-height: 1.5;
    border: none;
    background-color: rgba(255, 255, 255, 0.6);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='%238C92A0' d='M11.44 10.73l4.41 4.42a.5.5 0 1 1-.7.7l-4.42-4.41a6.5 6.5 0 1 1 .7-.7v-.01zM6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
    border-bottom: 1px solid #C5CBD5;
    width: 300px;
    padding: .5em 1em .5em 2.5em;
}

.input-bottom-border::placeholder {
    color: #838D99;
}

.input-bottom-border:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 1);
    border-bottom: 1px solid #84A2FA;
}

.input-grey-rounded {
    font-size: 16px;
    line-height: 1.5;
    background: #E9EEF0;
    border: 1px solid #D5D9DB;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='%238C92A0' d='M11.44 10.73l4.41 4.42a.5.5 0 1 1-.7.7l-4.42-4.41a6.5 6.5 0 1 1 .7-.7v-.01zM6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 15px 10px;
    background-size: 20px 20px;
    border-radius: 100px;
    width: 300px;
    padding: .5em 1em .5em 2.5em;
}

.input-grey-rounded::placeholder {
    color: #838D99;
}

.input-grey-rounded:focus {
    outline: none;
    background-color: #FFF;
    border: 1px solid #84A2FA;
}

.input-transparent {
    font-size: 16px;
    line-height: 1.5;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border: none;
    background: rgba(255, 255, 255, 0.2);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23FFFFFF' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
    border-radius: 5px;
    width: 300px;
    padding: .5em 1em .5em 2.5em;
    margin-bottom: 0px;
}

.input-transparent::placeholder {
    color: #FFF;
}

.input-transparent:focus {
    outline: none;
    background: rgba(255, 255, 255, 100);
    text-shadow: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%238C92A0' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
}