.modal-dialog {
    max-width: fit-content;
}

@media (min-width: 768px) {
    .modal-dialog.min-width {
        min-width: 500px;
    }
}

.day-time-div {
    border: 1px solid;
    padding: 10px 10px 0px 10px;
    margin: 0 0 10px 0;
}

.add-new-btn {
    width: fit-content;
    text-align: center;
    margin: auto;
}
